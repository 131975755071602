<template>
    <div class="cont-menu overflow-auto scroll-none menu">
        <div v-for="(item, idx) in listado" :key="idx" :class="!bloquearMenu(item) ? 'mx-0 my-3' : ''">
            <template v-if="!item.bloqueo">
                <router-link
                v-slot="{ navigate, isActive }"
                :data-xd="item.ruta"
                :to="{ name: `${item.ruta}`, params: item.params || {} }"
                :class="{'router-link-active': subIsActive(item.active)}"
                >
                    <a
                    class="d-middle-center position-relative cr-pointer ml-1"
                    :class="{'router-link-exact-active router-link-active': isActive}"
                    @click="navigate"
                    >
                        <div class="d-flex gap-2">
                            <div>
                                <div v-show="item.cant>0" class="badge">
                                    {{ item.cant }}
                                </div>
                                <i class="f-20" :class="item.icon" />
                            </div>
                            <div class="label d-flex align-items-center justify-content-between position-absolute rompe-palabras p-0" :class="{'text-blue': isActive}">
                                <span class="lh-12 f-12 f-light py-2 pl-2">{{ item.titulo }}</span>
                                <i class="arrow icon-chevron-right f-20 text-blue" />
                            </div>
                        </div>
                    </a>
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            menuIsExpanded: false,
            showMenuLabels: false,
            rutaActiva: '',
            menus: [
                { titulo: 'Dashboard', ruta: 'dashboard.main', icon: 'icon-dashboard', cant: 0, bloqueo: false },
                // { titulo: 'Pagos', ruta: 'pagos.pendientes', icon: 'icon-pay-chart', cant: 0 }, se unificó con el módulo de viviendas
                { titulo: 'Viviendas', ruta: 'viviendas.sistema', icon: 'icon-resident', cant: 0, bloqueo: false },
                { titulo: 'propietarios', ruta: 'propietarios', icon: 'icon-account-lock-open', cant: 0, bloqueo: false },
                { titulo: 'Anuncios', ruta: 'anuncios.main', icon: 'icon-ad', cant: 0, bloqueo: false },
                { titulo: 'Zonas comunes', ruta: 'zonas.main', icon: 'icon-reservas', cant: 0, bloqueo: false },
                { titulo: 'Peticiones, Quejas y Reclamos', ruta: 'pqr.main.index', icon: 'icon-pqr', cant: 0, bloqueo: false },
                { titulo: 'Chats', ruta: 'chats', icon: 'icon-speech-bubble', cant: 0, bloqueo: false },
                // { titulo: 'Ventanas emergentes', ruta: 'ventanas-emergentes', icon: 'icon-popup', cant: 0, bloqueo: false },
                { titulo: 'Encuestas', ruta: 'encuestas.main', icon: 'icon-help-circle-outline', cant: 0, bloqueo: false },
                { titulo: 'Llamados de atención', ruta: 'llamados.atencion.listado', icon: 'icon-alerta_2', cant: 0, bloqueo: false },
                { titulo: 'Información', ruta: 'informacion', icon: 'icon-informacion', cant: 0, bloqueo: false },
                { titulo: 'Vigilantes', ruta: 'vigilantes', icon: 'icon-vigilantes', cant: 0, bloqueo: false},
                { titulo: 'Correspondencia', ruta: 'correspondencia.listado', icon: 'icon-producto', cant: 0, bloqueo: false },
                { titulo: 'Gestión Administrativa', ruta: 'biblioteca.carpetas', icon: 'icon-folder-empty', cant: 0, bloqueo: false },
                { titulo: 'Registro de visitantes', ruta: 'visitantes.main', icon: 'icon-group-outline', cant: 0, bloqueo: false },
                { titulo: 'Configuración', ruta: 'configurar', icon: 'icon-cog', cant: 0, bloqueo: false },
            ],
            menusVigilante: [
                { titulo: 'Zonas comunes', ruta: 'zonas.historial', icon: 'icon-reservas', cant: 0, bloqueo: false },
                { titulo: 'Peticiones, Quejas y Reclamos', ruta: 'pqr.main.index', icon: 'icon-pqr', cant: 0, bloqueo: false },
                { titulo: 'Llamados de atención', ruta: 'llamados.atencion.listado', icon: 'icon-alerta_2', cant: 0, bloqueo: false },
                { titulo: 'Correspondencia', ruta: 'correspondencia.listado', icon: 'icon-producto', cant: 0, bloqueo: false },
                { titulo: 'Registro de visitantes', ruta: 'visitantes.main', icon: 'icon-group-outline', cant: 0, bloqueo: false },
            ],
            modulosBloqueados: JSON.parse(localStorage.getItem('conjunto_modulos')),
        }
    },
    computed: {
        isActive(){
            return this.$route.name
        },
        listado(){
            if(this.$usuario && this.$usuario.tipo == 4){
                return this.menusVigilante
            }
            return this.menus
        }
    },
    methods: {
        expandMenu(){
            this.menuIsExpanded = true
            setTimeout(() => {
                this.showMenuLabels = true
            }, 200);
        },
        collapseMenu(){
            this.menuIsExpanded = false
            this.showMenuLabels = false
        },
        menuSeleccionado(){
            return this.menus[0]
        },
        ir(ruta){
            this.rutaActiva=ruta
            this.$router.push({ name: ruta })
        },
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        },
        bloquearMenu(item){
            if(!this.modulosBloqueados)return false;

            const split = item.ruta.split('.')[0];
            if(split){
                switch(split.toLowerCase()){
                case "pqr":
                    return Number(this.modulosBloqueados.modulo_pqr) === 0 ? item.bloqueo = true : false;
                case "zonas":
                    return Number(this.modulosBloqueados.modulo_reserva) === 0 ? item.bloqueo = true : false;
                case "pagos":
                    return Number(this.modulosBloqueados.modulo_pagos) === 0 ? item.bloqueo = true : false;
                case "llamados":
                    return Number(this.modulosBloqueados.modulo_llamados) === 0 ? item.bloqueo = true : false;
                case "chats":
                    return Number(this.modulosBloqueados.modulo_chat) === 0 ? item.bloqueo = true : false;
                case "visitantes":
                    return Number(this.modulosBloqueados.modulo_visitas) === 0 ? item.bloqueo = true : false;
                default:
                    return false;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cont-menu{
    position: fixed;
    top:56px;
    width: 60px;
    height: calc(100vh - 61px);
    box-shadow: 1px 0px 2px #00000029;
    z-index: 999;
    transition: width .2s ease-in-out;
    .label{
        left: 56px; 
        width: 158px;
        padding-right: 10px;
        transition: opacity .2s ease-in-out; 
        border-radius: 0px 10px 10px 0px;
        min-height: 30px;
        max-height: fit-content;
        opacity: 0;
    }
    &:hover{
        background: white;
        width: 220px;
        .label{ 
            opacity: 1;
        }  
    }
}
/* .menu-lateral{
    width:60px;
    height: calc(100% - 130px);
} */
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    font-size: 8px;
    background: #FF4D59;
}
.menu{
    a{
        color: var(--text-muted);
        max-height: 30px;
        border-right: 3px solid transparent;
        .arrow{
            opacity: 1;
        }
        &:hover{
            color: var(--color-general);
            border-color:  var(--color-general);
            .label{
                background: #F5F5F5;
            }
            .arrow{
                opacity: 1;
            }
        }
        width:56px;height:56px;
        &.router-link-exact-active.router-link-active{
            color: var(--color-general);
            border-right: 3px solid var(--color-general);
            margin-left: 3px;
            
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
