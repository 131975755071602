<template>
    <div style="height:55px;">
        <nav class="navbar navbar-expand-lg border-bottom d-middle text-white">
            <!-- <router-link :to="{ name: 'home', }"> -->
            <div class="img-container">
                <div class="img cr-pointer" @click="ruta" />
            </div>
            <!-- </router-link> -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-menu" />
            </button>
            <div id="navbarSupportedContent" class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <p class="text-black-50 font-weight-bolder f-20 icon-home"> {{ conjunto ? conjunto.nombre : '' }} </p>
                        <!-- <img v-if="conjunto.logo" :src="conjunto.logo" alt="" />
                        <img v-else src="/img/no-imagen/mi-conjunto.svg" /> -->
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto mx-3">
                    <el-tooltip class="item" effect="light" placement="bottom-end" :visible-arrow="false" popper-class="br-4 text-5d border-0 shadow opciones-tooltip py-1">
                        <div slot="content" class="cr-pointer editar-opcion py-2 px-2 br-4">
                            <p class="f-14">Lineas de atención</p>
                        </div>
                        <li class="nav-item br-50 p-1 cr-pointer d-middle-center nav-user" @click="abrirModalLineas">
                            <i class="icon-phone f-12" />
                        </li>
                    </el-tooltip>
                </ul>
                <ul class="navbar-nav">
                    <el-tooltip class="item" effect="light" placement="bottom-end" :visible-arrow="false" popper-class="br-4 text-5d border-0 shadow opciones-tooltip py-1">
                        <div slot="content" class="cr-pointer editar-opcion py-2 px-2 br-4" @click="logout">
                            <p class="f-14">Cerrar sesión</p>
                        </div>
                        <li class="nav-item br-50 p-1 cr-pointer d-middle-center nav-user">
                            <p class="f-12">A</p>
                        </li>
                    </el-tooltip>
                </ul>
            </div>
        </nav>
        <!-- partials -->
        <modal ref="modalLineasAtencion" titulo="Lineas de atención">
            <div class="py-2 mx-4">
                <div v-if="contacto" class="d-middle bs-036-29 br-10 p-3 mb-4">
                    <i class="icon-id-badge mr-1 text-general f-20" />
                    <p class="f-16 f-600"> {{ contacto.nombre }} </p>
                    <p class="text-black-50 ml-auto f-15">{{ contacto.telefono }}</p>
                </div>
                <div v-for="(lineas, key) in lineasAtencion" :key="key">
                    <div class="d-middle border-bottom py-1">
                        <p class="f-600 f-15"> {{ lineas.nombre }} </p>
                        <p class="ml-auto text-black-50 f-15">{{ lineas.telefono }}</p>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import LineasAtencion from '~/services/lineas_atencion'
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            lineasAtencion: [],
            contacto: {}
        }
    },
    computed: {
        ...mapGetters({
            conjunto:'conjunto/conjunto',
        }),
    },
    created(){
        this.getLineasAtencion()
    },
    methods: {
        async logout(){
            await this.$store.dispatch('auth/logout')
            await this.$store.dispatch('conjunto/logOut')
            this.$router.push({name: 'login'})

        },
        ruta(){
            if (this.$usuario.tipo == 4){
                setTimeout( () => this.$router.push({ name: "zonas.historial"}), 2000);
            }else{
                setTimeout( () => this.$router.push({ name: "dashboard.main"}), 2000);
            }
        },
        abrirModalLineas(){
            this.$refs.modalLineasAtencion.toggle()
        },
        async getLineasAtencion(){
            try {
                const {data} = await LineasAtencion.getLineas()
                if(data.success){
                    this.lineasAtencion = data.data.lineas_atencion
                    this.contacto = data.data.contacto
                }
            } catch (e){
                this.errorCatch(e)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.navbar{
    position: fixed;
    width:100vw;
    top: 0px;
    height: 55px;
    z-index: 5;
    background: #fff;
    img{
        width: 150px;
        height: 84px;
    }
    .nav-user{
        background: var(--color-general);
        width: 24px;
        height: 24px;
    }
    .img-container{
        height: fit-content;
        padding-left: 60px;
        .img{
            background-image: url('/img/generales/logoOrbitaOscuro.png');
            background-size: contain;
            height: 80px;
            width: 80px;
        }
    }
}
.hover-i li:hover{
    background: #471F9B;
    border-radius: 12px;
}

@media screen and (max-width: 992px){
    .navbar{
        .img-container{
            // padding-top: -30px;
            .img{
                height: 50px;
                width: 50px;
            }
        }
    }
}
</style>
