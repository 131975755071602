<template>
    <div class="galeria5">
        <div class="img-padre" :style="`background-image: url( ${imgSeleccionadaSrc});`" /> 
        <div class="img-hijos mt-3 d-flex justify-content-start">
            <div v-for="data in listado" :key="data.id" class="img-child mr-2 br-4 cr-pointer" :class="imgSeleccionada == data.id ? 'seleccionado':''" @click="seleccionarImg(data.id, data.img)">
                <img :src="data.img" alt="" class="br-4 w-100 h-100">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GaleriaCinco',
    data(){
        return{
            // listadoImagenes: [
            //     { id: 0,img: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
            //     { id: 1,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
            //     { id: 2,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
            //     { id: 3,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
            //     { id: 4,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
            // ],
            imgSeleccionada: null,
            imgSeleccionadaSrc: '/img/noImg/anuncio.svg',
        }
    },
    props: {
        listado: {
            type: Array,
            default: () => []
        }
    },
    methods:{
        seleccionarImg(id,bg){
            this.imgSeleccionada = id
            this.imgSeleccionadaSrc = bg
        },
    }
    
}
</script>
<style lang="scss" scoped>
.galeria5{
    .img-padre{
        width:600px; 
        height:430px;
        background-size: 100% 100%;
        border-radius: 4px;
        transition: background-image 0.5s ease-in-out;
    }
    .img-hijos{
        width:488px;
        .img-child{
            width: 87px;
            height: 87px;
            
            &:last-child{
                margin-right: 0px !important;
            }
            &.seleccionado{
                border: 2px solid #109881;
                &>img{
                    border: 2px solid white;
                }
            }
        }
    }
}
</style>