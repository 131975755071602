export default [
    {
        path: '/pagos-pendientes',
        name: 'pagos.pendientes',
        component: () => import('../../pages/pagos').then(m => m.default || m),
    },
    {
        path: '/pagos-pendientes/ver/:id_pago?',
        name: 'verificar.pago',
        component: () => import('../../pages/pagos/verificarPago').then(m => m.default || m),
    },
]
